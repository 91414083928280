.arrowCircle {
    width: 32px;
    height: 32px;
    border-radius: 57px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    cursor: pointer;
}

.arrowCircleNav {
    width: 24px;
    height: 24px;
    position: relative;
    top: 4px;
    left: 3px;
}

.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
}

.menu-item-wrapper {
    margin-right: 24px;
}
