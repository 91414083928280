#AppContainer::-webkit-scrollbar {
    display: none;
}

#hideScrollbar::-webkit-scrollbar {
    display: none;
}

*:focus {
    outline: none;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
    background-color: #ffccbc !important;
}

.slick-slide {
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
}

.slick-track {
    margin-left: 0 !important;
}
